import { PLATFORMS } from "../../../../core/constants";
export const PLATFORM_OPTIONS = Object.keys(PLATFORMS).map(
  (key) => PLATFORMS[key]
);

export const currencyOptions = [
  { label: "AUD", value: "AUD" },
  { label: "NZD", value: "NZD" },
];

export const layoutOptions = [
  { label: "Standard", value: "standard" },
  { label: "Inversed", value: "inversed" },
  { label: "Custom", value: "custom" },
  { label: "Logo only", value: "logo-only" },
  { label: "Disabled", value: "disabled" },
];

export const paymentOptions = [
  { label: "Equal Monthly", value: "equal" },
  { label: "Minimum Monthly or Flexible", value: "minimum" },
];

export const promotionMonthsOptions = [
  { label: "6 Months", value: "6" },
  { label: "12 Months", value: "12" },
  { label: "18 Months", value: "18" },
  { label: "24 Months", value: "24" },
  { label: "36 Months", value: "36" },
  { label: "48 Months", value: "48" },
  { label: "50 Months", value: "50" },
  { label: "60 Months", value: "60" },
];

export const environmentOptions = [
  { label: "Production", value: "prod" },
  { label: "Test", value: "test" },
];

export const initialValues = {
  platform: "directintegration",
  productName: "Product Name",
  productPrice: "1000.00",
  currency: "AUD",
  layout: "standard",
  paymentOption: "equal",
  promotionMonths: "12",
  stackedLogo: false,
  advancedConfigCheck: false,
  advancedConfig: "",
  environment: "test",
  merchantId: "merchantId",
  minAmount: "250",
};
