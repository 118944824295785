import React from "react";
import styled from "styled-components";
import { Header } from "../../components";
import { COLOR, BREAKPOINT } from "../../components/theme";

const WRAPPER_SPACING = "72px 24px";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr auto;
  grid-template-areas:
    "nav"
    "main";
  height: 100vh;
`;

const Nav = styled.nav`
  grid-area: "nav";
  background: white;
  width: 100%;
  height: 100%;
  max-width: ${BREAKPOINT.SIZE_DESKTOP};
  margin: 0 auto;
`;

const Main = styled.main`
  grid-area: "main";
  background: ${COLOR.SECONDARY_LIGHT_BLUE_25};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  max-width: ${BREAKPOINT.SIZE_DESKTOP};
  margin: 0 auto;

  @media screen and (max-width: ${BREAKPOINT.SIZE_MOBILE}) {
    flex-direction: column;
  }
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: ${WRAPPER_SPACING};
`;

const Scene = ({ children }) => (
  <Grid>
    <Nav>
      <Header />
    </Nav>
    <Main>
      <Wrapper>{children}</Wrapper>
    </Main>
  </Grid>
);

const Item = ({ children }) => <ContentItem>{children}</ContentItem>;

Scene.Item = Item;

export default Scene;
