import React from "react";
import { Text, Container, List } from "basis";

import { Scene } from "../../layout/playground";
import Configuration from "../../layout/playground/widget/Testing/Configuration";
import { Loading } from "../../components";

const WidgetTesting = () => {
  return (
    <>
      <Scene>
        <Scene.Item>
          <Configuration />
        </Scene.Item>

        <Scene.Item>
          <Container bg="secondary.lightBlue.t15" padding="6 8">
            <Text textStyle="heading3" margin="0 0 4">
              Preview
            </Text>
            <Container bg="white" hasBorder padding="6 8">
              <div id="latitude-banner-container" className="banner-content">
                <Loading />
              </div>
              <div
                id="latitude-banner-container-copy"
                className="banner-content"></div>
              <div
                id="latitude-banner-container-image"
                className="banner-content"></div>
              <div
                id="latitude-banner-container-link"
                className="banner-content"></div>
            </Container>
            <Text textStyle="heading5" margin="4 0 2">
              Container Names:
            </Text>
            <List>
              <List.Item>
                <b>Banner:</b> latitude-banner-container
              </List.Item>
              <List.Item>
                <b>Custom Banner - Copy:</b> latitude-banner-container-copy
              </List.Item>
              <List.Item>
                <b>Custom Banner - Image:</b> latitude-banner-container-image
              </List.Item>
              <List.Item>
                <b>Custom Banner - Link:</b> latitude-banner-container-link
              </List.Item>
            </List>
          </Container>
        </Scene.Item>
      </Scene>
    </>
  );
};

export default WidgetTesting;
