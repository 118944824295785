import React, { useState } from "react";
import {
  Container,
  RadioGroup,
  Select,
  Stack,
  Input,
  Button,
  Form,
  Checkbox,
  Textarea,
  Text,
} from "basis";
import { appUrl, featureToggles } from "../../../../core/config";
import {
  currencyOptions,
  layoutOptions,
  paymentOptions,
  promotionMonthsOptions,
  initialValues,
  PLATFORM_OPTIONS,
} from "./constants";

export const Configuration = () => {
  const [layoutType, setLayout] = useState("");
  const [advancedConfig, setAdvancedConfig] = useState(false);
  const [jsonError, setJsonError] = useState(false);

  const onSubmit = (e) => {
    const formValues = e.values;

    const insertedScript = document.getElementById("insertedJS");
    if (insertedScript) {
      insertedScript.remove();
    }

    let parsed = {};
    try {
      setJsonError(false);
      parsed = JSON.parse(formValues.advancedConfig);
      window.LatitudeCheckoutOverride = parsed;
    } catch (e) {
      setJsonError(true);
    }

    const insertedContainers = document.getElementsByClassName(
      "banner-content"
    );

    for (var i = 0; i < insertedContainers.length; i++) {
      if (this !== insertedContainers[i]) {
        insertedContainers[i].innerHTML = "";
      }
    }

    if (formValues.platform !== "shopify") {
      const LatitudeCheckout = {
        merchantId: formValues.merchantId,
        currency: formValues.currency,
        container: "latitude-banner-container",
        copyContainer: "latitude-banner-container-copy",
        imageContainer: "latitude-banner-container-image",
        linkContainer: "latitude-banner-container-link",
        stackedLogo: formValues.stackedLogo,
        page: "product",
        layout: formValues.layout,
        paymentOption: formValues.paymentOption,
        promotionMonths: formValues.promotionMonths,
        price: formValues.productPrice,
        minAmount: formValues.minAmount,
        product: {
          name: formValues.productName,
          price: formValues.productPrice,
        },
      };

      window.LatitudeCheckout = LatitudeCheckout;
      const script = document.createElement("script");
      script.src = `${appUrl}/assets/content.js`;
      script.charset = "utf-8";
      script.async = true;
      script.id = "insertedJS";
      document.head.appendChild(script);

      return;
    }

    const meta = {
      product: {
        variants: [
          {
            price: formValues.productPrice * 100,
            name: formValues.productName,
          },
        ],
      },
      page: {
        pageType: "product",
      },
    };

    const Shopify = {
      shop: "lfsgreen.myshopify.com",
      currency: {
        active: formValues.currency,
      },
      theme: {
        name: "Debut",
      },
    };

    window.Shopify = Shopify;
    window.meta = meta;
    const script = document.createElement("script");
    script.src = `${appUrl}/assets/shopify.js?layout=${formValues.layout}&paymentOption=${formValues.paymentOption}&promotionMonths=${formValues.promotionMonths}&shop=lfsgreen.myshopify.com&merchantId=${formValues.merchantId}&containerId=latitude-banner-container&copyContainer=latitude-banner-container-copy&imageContainer=latitude-banner-container-image&linkContainer=latitude-banner-container-link&minAmount=${formValues.minAmount}`;

    script.charset = "utf-8";
    script.async = true;
    script.id = "insertedJS";
    document.head.appendChild(script);
  };

  const changeLayout = (value) => {
    setLayout(value.selectedOption.value);
  };

  const changeAdvancedConfig = (value) => {
    setAdvancedConfig(value.isChecked);
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {({ state }) => (
        <Container bg="secondary.lightBlue.t15" padding="4 6">
          <Stack gap="4">
            <Select
              name="platform"
              options={PLATFORM_OPTIONS}
              label="Select Platform"
            />
            <RadioGroup
              name="currency"
              color="white"
              label="Base currency"
              options={currencyOptions}
            />
            <Select
              name="layout"
              label="Layout"
              options={layoutOptions}
              margin="3 0"
              onChange={changeLayout}
            />
            {layoutType === "custom" && (
              <Checkbox
                name="stackedLogo"
                label="Stacked Logo (custom layout)"
                optional>
                Yes
              </Checkbox>
            )}
            {featureToggles.isPriceBreakdownEnabled && (
              <Select
                name="paymentOption"
                label="Interest Free plan type"
                options={paymentOptions}
                margin="3 0"
              />
            )}
            <Select
              name="promotionMonths"
              label="Payment plan period"
              options={promotionMonthsOptions}
              margin="3 0"
            />

            <Input
              name="productName"
              label="Product Name"
              margin="3 0"
              optional
              type="text"
            />
            <Input
              name="productPrice"
              label="Product Price"
              margin="3 0"
              optional
              variant="decimal"
              type="text"
              prefix="$"
            />
            <Input
              name="merchantId"
              label="Merchant Id"
              margin="3 0"
              optional
              type="text"
            />
            <Input
              name="minAmount"
              label="Minimum Amount for widget to show"
              margin="3 0"
              optional
              type="text"
            />
            <Checkbox
              onChange={changeAdvancedConfig}
              name="advancedConfigCheck"
              label="Advanced Config?"
              margin="3 0"
              optional>
              Yes
            </Checkbox>
            {advancedConfig && (
              <>
                <Textarea
                  margin="3 0"
                  name="advancedConfig"
                  label="Advanced Config"
                  height="200"
                  placeholder='{ "productWidget": { "layout": "standard", "promotionMonths": 12 } }'
                  optional
                />
                {jsonError && (
                  <Text color="conditional.negative.text">
                    Please enter a valid JSON
                  </Text>
                )}
              </>
            )}
            <Button type="submit">Create Preview</Button>
          </Stack>
        </Container>
      )}
    </Form>
  );
};

export default Configuration;
