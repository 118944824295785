import React from "react";
import { Text, Container, Stack, Link, Message } from "basis";

const InfoCard = ({ title, description, message, children }) => {
  const desc = description && <Text>{description}</Text>;

  const info = message && (
    <Message severity="info-or-minor" bg="grey.t05">
      {message}
    </Message>
  );

  const heading = (
    <Stack gap="4">
      <Text textStyle="heading1">{title}</Text>
      {desc}
      {info}
    </Stack>
  );

  const content = <Container margin="8 0">{children}</Container>;

  const footer = (
    <Stack gap="1">
      <Text textStyle="heading6">Need help ?</Text>
      <Link href="mailto:applyandbuy.anz@latitudefinancial.com" newTab>
        applyandbuy.anz@latitudefinancial.com
      </Link>
    </Stack>
  );

  return (
    <Stack gap="8">
      {heading}
      {footer}
      {content}
    </Stack>
  );
};

export default InfoCard;
